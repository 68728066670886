import jsCookies from 'js-cookie';
import {
  AVAILABLE_LANGUAGES,
  DISTRIBUTOR_DATA_COOKIE,
  DOMAIN,
  LANGUAGE_COOKIE,
  USER_TOKEN_COOKIE,
} from '../../settings';
import { Languages } from './getFormattedPrice';
import { GDOInfoDataInterface } from '../types';

export function getBrowserLanguage(): string | undefined {
  return navigator.language?.split('-')[0];
}

export function getCookieLanguage() {
  return (jsCookies.get(`${LANGUAGE_COOKIE}`) || 'it') as Languages;
}

export function setCookieLanguage(lang: string): void {
  jsCookies.set(`${LANGUAGE_COOKIE}`, lang, { domain: DOMAIN });
}

export function getCookieUserToken(): string {
  return jsCookies.get(`${USER_TOKEN_COOKIE}`) || '';
}

export function setCookieUserToken(userToken: string): void {
  jsCookies.set(`${USER_TOKEN_COOKIE}`, userToken, { domain: DOMAIN, expires: 30 });
}

export function getGDOInfoData(): GDOInfoDataInterface {
  const gdoCookie = jsCookies.get(`${DISTRIBUTOR_DATA_COOKIE}`) || '';
  const currentLang = getLanguage();
  const distributorCodeList: Record<string, string> = {
    it: '31000784',
    es: '32100003',
  };

  const selectedDistributorCode = distributorCodeList[currentLang] || distributorCodeList.it;

  const defaultGDOData = { currentSiteDeliveryType: '', distributorCode: selectedDistributorCode, logo: '', name: '' };

  if (!gdoCookie) {
    return defaultGDOData;
  }

  return JSON.parse(decodeURIComponent(gdoCookie));
}

export function getLanguage(): string {
  const cookieLanguage = getCookieLanguage();

  if (typeof cookieLanguage !== 'undefined' && AVAILABLE_LANGUAGES.includes(cookieLanguage)) {
    return cookieLanguage;
  }

  const browserLanguage = getBrowserLanguage();

  if (typeof browserLanguage !== 'undefined' && AVAILABLE_LANGUAGES.includes(browserLanguage)) {
    return browserLanguage;
  }
  return AVAILABLE_LANGUAGES[0];
}

export function getUserToken(): string {
  return sessionStorage.getItem('accessToken') || jsCookies.get('userToken') || '';
}

export function getCurrentYear(): number {
  return new Date().getUTCFullYear();
}

export function formatDate(date: string | null | undefined): string {
  if (!date) return '-';
  if (!window.Intl || typeof window?.Intl !== 'object') {
    return date;
  }
  const lang = getLanguage();
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return new Intl.DateTimeFormat(lang, options).format(new Date(date));
}

export function getBetaUser() {
  return !!jsCookies.get('newCart');
}

export function hasDataDogCookie() {
  return (
    jsCookies.get('datadog-synthetics-public-id') !== undefined ||
    jsCookies.get('datadog-synthetics-result-id') !== undefined
  );
}
