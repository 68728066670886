export const AVAILABLE_LANGUAGES: string[] = ['it', 'en', 'es', 'fr', 'de', 'nl'];
export const LANGUAGE_COOKIE: string = 'phs_choosen_language';
export const USER_TOKEN_COOKIE: string = 'userToken';
export const DOMAIN: string = `${import.meta.env.VITE_APP_DOMAIN}`;
export const API_ROOT: string = `${import.meta.env.VITE_APP_API_ROOT}`;
export const X_API_KEY: string = `${import.meta.env.VITE_APP_X_API_KEY}`;
export const SENTRY_DSN: string = `${import.meta.env.VITE_APP_SENTRY_DSN}`;
export const GTM_ID: string = `${import.meta.env.VITE_APP_GTM_ID}`;
export const PHOTOSI_URL: string = `${import.meta.env.VITE_APP_PHOTOSI_URL}`;
export const PHOTOSI_LOGIN_URL: string = `${import.meta.env.VITE_APP_PHOTOSI_LOGIN_URL}`;
export const DIDOMI_API_KEY: string = `${import.meta.env.VITE_APP_DIDOMI_API_KEY}`;
export const DIDOMI_AMPLITUDE_CONSENT_ID = 'c:amplitude';
export const AMPLITUDE_API_KEY = import.meta.env.VITE_APP_AMPLITUDE_API_KEY
  ? import.meta.env.VITE_APP_AMPLITUDE_API_KEY
  : '';
export const DIDOMI_CONSENT_ID: string = `${import.meta.env.VITE_APP_DIDOMI_CONSENT_ID}`;
export const CART_URL: string = `${import.meta.env.VITE_APP_PHOTOSI_CART_URL}`;
export const DOWNLOAD_APP_URL: string = `${import.meta.env.VITE_PATH_DOWNLOAD_APP}`;
export const BRAZE_API_KEY: string = import.meta.env.VITE_BRAZE_API_KEY;
export const BRAZE_BASE_URL: string = import.meta.env.VITE_BRAZE_BASE_URL;
export const BRAZE_SERVICEWORKER_LOCATION: string = import.meta.env.VITE_BRAZE_SERVICEWORKER_LOCATION;
export const RESERVED_AREA_URL: string = import.meta.env.VITE_RESERVED_AREA_URL;
export const API_SOFTWARE_CODE: string = 'PHOTOSI_WEB_CART';
export const API_SOFTWARE_VERSION: string = '2.0';
export const DISTRIBUTOR_DATA_COOKIE: string = 'GDOInfoData';
