import { api } from './api';
import { getGDOInfoData } from '../utils';
import { API_SOFTWARE_CODE, API_SOFTWARE_VERSION } from '../../settings';

const params = {
  softwareCode: API_SOFTWARE_CODE,
  softwareVersion: API_SOFTWARE_VERSION,
  distributor: getGDOInfoData().distributorCode,
};

export const cartApi = api.create({
  params,
});
