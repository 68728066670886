const confidentialPriceStorageKey = 'confidential_price';

export function getConfidentialPriceStorage() {
  const isConfidentialPriceStorage = localStorage.getItem(confidentialPriceStorageKey);

  if (isConfidentialPriceStorage !== null) {
    return isConfidentialPriceStorage === 'true';
  }

  return isConfidentialPriceStorage;
}

export function setConfidentialPriceStorage(value: boolean) {
  localStorage.setItem(confidentialPriceStorageKey, value.toString());
}
