export type StringFormat = 'capitalize' | 'lowercase' | 'uppercase' | 'uppercaseFirst';

type StringFormatter = Record<StringFormat, (value: string) => string>;

export const stringFormatter: StringFormatter = {
  capitalize: (value: string) => value.replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase()),
  lowercase: (value: string) => value.toLowerCase(),
  uppercase: (value: string) => value.toUpperCase(),
  uppercaseFirst: (value: string) => value.charAt(0).toUpperCase() + value.slice(1),
};
