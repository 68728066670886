import { RouteObject } from 'react-router-dom';
import { PATHS } from './paths';
import { Error404, PrivateRoutes } from './_shared/components';
import { Crash } from './_shared/pages/Crash';
import { CartConsumerLayout } from './cart-consumer/components';
import { CartRetailerLayout } from './cart-retailer/components';
import { Login } from './_shared/pages';
import { lazy } from 'react';

const Home = lazy(() => import('./_shared/pages/Home/Home'));
const Shop = lazy(() => import('./_shared/pages/Shop/Shop'));
const CartConsumer = lazy(() => import('./cart-consumer/pages/CartConsumer/CartConsumer'));
const CartRetailer = lazy(() => import('./cart-retailer/pages/CartRetailer/CartRetailer'));
const RetailerSummary = lazy(() => import('./cart-retailer/pages/RetailerSummary/RetailerSummary'));
const RetailerBuyerInfo = lazy(() => import('./cart-retailer/pages/BuyerInfo/BuyerInfo'));
const RetailerCheckout = lazy(() => import('./cart-retailer/pages/RetailerCheckout/RetailerCheckout'));
const CrossSelling = lazy(() => import('./cross-selling/pages/CrossSelling/CrossSelling'));
const ThankYou = lazy(() => import('./cart-retailer/pages/ThankYou/ThankYou'));

export const routes: RouteObject[] = [
  {
    path: PATHS.INDEX,
    element: <PrivateRoutes />,
    children: [
      { index: true, element: <Home /> },
      {
        path: PATHS.CROSS_SELLING,
        children: [
          {
            index: true,
            element: <CrossSelling />,
          },
        ],
      },
      {
        path: PATHS.SHOP,
        children: [
          {
            index: true,
            element: <Shop />,
          },

          {
            path: PATHS.CART_CONSUMER,
            element: <CartConsumerLayout />,
            children: [
              {
                index: true,
                element: <CartConsumer />,
              },
            ],
          },
          {
            path: PATHS.CART_RETAILER,
            element: <CartRetailerLayout />,
            children: [
              {
                index: true,
                element: <CartRetailer />,
              },
              {
                path: PATHS.SUMMARY,
                element: <RetailerSummary />,
              },
              {
                path: PATHS.BUYER_INFO,
                element: <RetailerBuyerInfo />,
              },
              {
                path: PATHS.CHECKOUT,
                element: <RetailerCheckout />,
              },
              {
                path: PATHS.THANK_YOU,
                element: <ThankYou />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: PATHS.LOGIN,
    element: <Login />,
  },
  {
    path: PATHS.CRASH,
    element: <Crash />,
  },
  {
    path: PATHS.NOT_FOUND,
    element: <Error404 />,
  },
];
