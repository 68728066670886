import { useQuery } from '@tanstack/react-query';
import { getUserType } from '../../api';
import { isConsumer, isRetailer } from '../../utils';

export function useGetUserType() {
  const { data, ...rest } = useQuery({
    queryKey: ['user-info'],
    queryFn: getUserType,
  });

  const isRetailerUser = isRetailer(data?.types);
  const isConsumerUser = isConsumer(data?.types);

  return {
    data,
    isRetailerUser,
    isConsumerUser,
    ...rest,
  };
}
