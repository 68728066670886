import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addRetailerCouponCode } from '../../api';
import { useGetRetailerCartHeader } from '..';
import { useGetCartSearchParams } from '../../../_shared/hooks';
import { Amplitude as amplitude } from '../../../_shared/utils';

export function useAddRetailerCouponCode() {
  const queryClient = useQueryClient();
  const { cartGuid } = useGetCartSearchParams();
  const { data } = useGetRetailerCartHeader();
  const selectedCartGuid = data?.id || cartGuid;

  return useMutation({
    mutationKey: ['addRetailerCouponCode'],
    mutationFn: (couponCode: string) => addRetailerCouponCode(selectedCartGuid, couponCode),
    onSuccess: (_data, promoCode) => {
      queryClient.invalidateQueries({ queryKey: ['cartHeaders'] });
      queryClient.invalidateQueries({ queryKey: ['retailerIplabsHeader'] });
      amplitude.promoCodeSuccess(promoCode);
    },
    onError: (err: any, couponCode) => {
      amplitude.promoCodeError({ couponCode: couponCode, couponErrorCode: err.response._data.errorCode });
    },
  });
}
