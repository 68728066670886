import * as Sentry from '@sentry/react';
import { FooterPayments, Header, Typography, Button, Container } from '@photosi/web-design-system';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import crashImage from '../../assets/images/cat_in_the_box.svg';

export const Crash: React.FC = () => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const errorMessage: string = queryParams.get('errorMessage') || '';
  const nextUrl: string = queryParams.get('nextUrl') || '';

  useEffect(() => {
    Sentry.captureMessage('IPLABS_ADD_TO_CART_ERROR', {
      contexts: {
        extra: { message: errorMessage },
      },
    });
  }, [errorMessage]);

  const handleClick = () => {
    if (nextUrl) {
      window.location.href = nextUrl;
    }
  };

  return (
    <>
      <Header />
      <Container mxAuto>
        <div className="pds-m-8 pds-text-center">
          <Typography variant="heading2XL" className="pds-text-center">
            {t('crashPage.title', { case: 'uppercaseFirst' }) as string}
          </Typography>
          <img src={crashImage} alt="error" className="pds-mx-auto pds-my-8 pds-w-1/2 pds-max-w-sm" />

          <div className="pds-my-8">
            <Typography variant="bodyS" className="pds-text-center">
              {errorMessage}
            </Typography>
          </div>
          <div className="pds-flex pds-justify-center">
            <Button
              data-testid="retry-button"
              size="lg"
              onClick={() => {
                handleClick();
              }}
            >
              {t('crashPage.cta', { case: 'uppercaseFirst' })}
            </Button>
          </div>
        </div>
      </Container>
      <FooterPayments />
    </>
  );
};
