export enum PATHS {
  INDEX = '/',
  LOGIN = 'login',
  SHOP = 'shop',
  CART_CONSUMER = 'consumer',
  CART_RETAILER = 'business',
  SUMMARY = 'summary',
  BUYER_INFO = 'customer-info',
  CHECKOUT = 'checkout',
  THANK_YOU = 'thank-you',
  CROSS_SELLING = 'cross-selling',
  CRASH = 'crash',
  DEV = 'develop',
  NOT_FOUND = '*',
}

export type PathsKey = keyof typeof PATHS;
