import React, { PropsWithChildren } from 'react';
import { getConfidentialPriceStorage, setConfidentialPriceStorage } from '../../utils';

type ConfidentialPriceContextValue = {
  isConfidentialPriceActive: boolean | null;
  setIsConfidentialPriceActive: (value: boolean) => void;
};

export const ConfidentialPriceContext = React.createContext<ConfidentialPriceContextValue>(
  {} as ConfidentialPriceContextValue,
);

export const ConfidentialPriceProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const isConfidentialPriceStorageActive = getConfidentialPriceStorage();
  const [isConfidentialPriceActive, setIsConfidentialPriceActive] = React.useState(isConfidentialPriceStorageActive);

  function handleConfidentialPriceChange(value: boolean) {
    setIsConfidentialPriceActive(value);
    setConfidentialPriceStorage(value);
  }

  return (
    <ConfidentialPriceContext.Provider
      value={{
        isConfidentialPriceActive,
        setIsConfidentialPriceActive: handleConfidentialPriceChange,
      }}
    >
      {children}
    </ConfidentialPriceContext.Provider>
  );
};
