import { Link } from 'react-router-dom';
import { CartStep } from './CartRetailerStepper';
import { Typography, mergeClasses } from '@photosi/web-design-system';

type StepProps = CartStep & {
  isActive: boolean;
  index: number;
};

export const Step: React.FC<StepProps> = ({ label, path, index, isActive }) => {
  return (
    <Link to={path} className={mergeClasses(!isActive && 'pds-pointer-events-none')}>
      <div className="pds-flex pds-flex-col pds-items-center pds-justify-center pds-gap-2">
        <div
          className={mergeClasses(
            'pds-relative pds-flex pds-items-center pds-justify-center pds-gap-3 pds-size-8 pds-bg-gray-400 pds-rounded-full pds-text-white pds-text-center',
            isActive && 'pds-bg-water-500 ',
          )}
        >
          {index + 1}
        </div>
        <Typography
          variant="labelBoldM"
          className={mergeClasses('pds-text-gray-400 pds-font-normal', isActive && 'pds-text-water-500')}
        >
          {label}
        </Typography>
      </div>
    </Link>
  );
};
