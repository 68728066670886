import { buttonVariants, Typography } from '@photosi/web-design-system';
import emptyCartImage from '../../assets/images/empty-cart-image.svg';
import { useTranslation } from 'react-i18next';
import { PHOTOSI_URL } from '../../../settings';

const emptyCartList: { title: string; description: string }[] = [
  {
    title: 'cartConsumerPage.emptyStatePage.list.firstPoint.title',
    description: 'cartConsumerPage.emptyStatePage.list.firstPoint.description',
  },
  {
    title: 'cartConsumerPage.emptyStatePage.list.secondPoint.title',
    description: 'cartConsumerPage.emptyStatePage.list.secondPoint.description',
  },
  {
    title: 'cartConsumerPage.emptyStatePage.list.thirdPoint.title',
    description: 'cartConsumerPage.emptyStatePage.list.thirdPoint.description',
  },
  {
    title: 'cartConsumerPage.emptyStatePage.list.fourthPoint.title',
    description: 'cartConsumerPage.emptyStatePage.list.fourthPoint.description',
  },
];

const { button } = buttonVariants();

export const EmptyCart: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="pds-bg-white pds-w-full pds-py-12 pds-px-7">
      <div className="pds-container pds-mx-auto">
        <div className="pds-flex md:pds-justify-around pds-flex-col-reverse md:pds-flex-row">
          <div className="pds-pb-20 md:pds-pb-6 lg:pds-pb-0 lg:w-1/2 w-full">
            <div className="pds-h-full pds-flex pds-flex-col">
              <Typography variant="headingXL" className="pds-text-orange-500">
                {t('cartConsumerPage.emptyStatePage.title', { case: 'uppercase' }) as string}
              </Typography>
              <ul className="pds-flex pds-flex-col pds-mt-7 pds-gap-7" data-testid="empty-cart-list-container">
                {emptyCartList.map(({ description, title }, index) => (
                  <li className="pds-flex pds-gap-5" key={title}>
                    <div className="pds-flex pds-items-center pds-justify-center pds-rounded-full pds-size-12 pds-bg-orange-500 pds-p-1 ">
                      <Typography variant="headingXL" className="pds-text-white pds-font-bold pds-text-center ">
                        {index + 1}
                      </Typography>
                    </div>
                    <div className="pds-w-4/5">
                      <Typography variant="headingL" className="pds-font-bold">
                        {t(title)}
                      </Typography>
                      <Typography variant="headingL" className="pds-font-normal pds-mt-1">
                        {t(description)}
                      </Typography>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="pds-pb-20 md:pds-pb-6 lg:pds-pb-0 md:pds-border-l md:pds-border-l-solid pds-border-[#f5f5f5] lg:pds-w-1/2 pds-w-full">
            <div className="pds-h-full pds-flex pds-flex-col">
              <div className="pds-flex-1 pds-flex pds-flex-col pds-flex-wrap md:pds-justify-around md:pds-items-center pds-gap-2">
                <img src={emptyCartImage} alt="error" className="pds-mx-auto pds-my-8 pds-max-w-sm pds-w-full" />
                <Typography variant="headingXL">
                  {t('cartConsumerPage.emptyStatePage.newCartTitle') as string}
                </Typography>
                <Typography variant="bodyM">
                  {t('cartConsumerPage.emptyStatePage.newCartDescription') as string}
                </Typography>
                <a className={button({ size: 'md', className: 'pds-mt-1' })} href={PHOTOSI_URL}>
                  {t('cartConsumerPage.emptyStatePage.newCartButtonText') as string}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
