export enum USERINFO_ACTION_TYPES {
  ADD_USERINFO_ACTION = 'ADD_USERINFO_ACTION',
}

export enum UserType {
  USER_CONSUMER_TYPE = 'ConsumerUser',
  USER_RETAILER_TYPE = 'RetailerUser',
  USER_PROFESSIONAL_TYPE = 'ProfessionalUser',
}

export type UserTypeInfo = {
  id: string;
  types: UserType[];
};

export type UserInfo = {
  id: number;
  email: string;
  isStaff: boolean;
  isActive: boolean;
};

export type UserMarketingInfo = {
  hasAlreadyOrdered: boolean;
  id: number;
  lastLoginType: string;
  lastShippingType: string;
  lastShopCode: string;
  registrationDate: string;
};

export type UserShippingAddressesInfo = {
  id: number;
};
