export function setRetryOptions(failureCount: number, error: any) {
  const maxRetryCount = 3;
  const refusedRetryCodes = [401, 403, 404];

  if (failureCount >= maxRetryCount) {
    return false;
  }

  if (refusedRetryCodes.includes(error?.response?.status || '')) {
    return false;
  }

  return true;
}
