import { Outlet, useLocation } from 'react-router-dom';
import { useGetUserType } from '../../hooks';
import { PATHS } from '../../../paths';
import { Navigate } from '../Navigate';
import { Suspense } from 'react';
import { Loader } from '@photosi/web-design-system';
import * as Sentry from '@sentry/react';
import * as braze from '@braze/web-sdk';
import { CookieConsent } from '../CookieConsent';

export const PrivateRoutes: React.FC = () => {
  const { pathname, search } = useLocation();
  const { data, isError } = useGetUserType();

  if (data?.id) {
    braze.changeUser(data.id.toString());
  }

  if (isError) {
    Sentry.captureMessage('USER_NOT_FOUND', 'error');
    const nextSearchParam = search ? `${pathname}${search}` : pathname;
    return <Navigate to={{ pathname: `/${PATHS.LOGIN}`, search: `next=${nextSearchParam}` }} />;
  }

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
      <CookieConsent />
    </>
  );
};
