/* eslint-disable no-restricted-imports */
import { Navigate as NativeNavigate } from 'react-router-dom';
import { CustomPath, NavigateOptions } from '../../types';
import { useSearchParamHandler } from '../../hooks';

/**
 * Custom navigation component that increases search parameter handling.
 * @param  props.to - The destination path object.
 * @param props.keepSearchParams - Whether to keep existing search parameters.
 * @param props.skipSearchParam - Search parameters to skip or remove.
 * @param  props.options - Navigation options.
 */
export const Navigate: React.FC<{ to: CustomPath } & NavigateOptions> = ({
  to,
  keepSearchParams,
  skipSearchParam,
  ...options
}) => {
  const getCurrentSearchParams = useSearchParamHandler();
  const currentSearchParams = getCurrentSearchParams(to.search, { keepSearchParams, skipSearchParam });

  return <NativeNavigate to={{ pathname: to.pathname, search: currentSearchParams }} {...options} />;
};
