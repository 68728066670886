import { useLocation } from 'react-router-dom';
import { PATHS } from '../../../paths';
import { Step } from './Step';
import { useTranslation } from 'react-i18next';

export type CartStep = {
  label: string;
  path: string;
};

const cartStepList: CartStep[] = [
  { label: 'stepper.cart', path: `/${PATHS.SHOP}/${PATHS.CART_RETAILER}/${PATHS.SUMMARY}` },
  { label: 'stepper.buyerInfo', path: `/${PATHS.SHOP}/${PATHS.CART_RETAILER}/${PATHS.BUYER_INFO}` },
  { label: 'stepper.checkout', path: `/${PATHS.SHOP}/${PATHS.CART_RETAILER}/${PATHS.CHECKOUT}` },
];

export const CartRetailerStepper: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const currentStepIndex = cartStepList.findIndex(step => pathname.includes(step.path));
  return (
    <div className="pds-flex pds-justify-center pds-items-center pds-gap-14 max-sm:pds-gap-8">
      {cartStepList.map(({ label, path }, index) => {
        return <Step key={path} isActive={index <= currentStepIndex} index={index} label={t(label)} path={path} />;
      })}
    </div>
  );
};
