import { useQuery } from '@tanstack/react-query';
import { getRetailerCardHeader } from '../../api/getRetailerCardHeader';
import { useEffect } from 'react';
import * as sentry from '@sentry/browser';

export function useGetRetailerCartHeader() {
  const { isError, ...rest } = useQuery({
    queryKey: ['cartHeaders'],
    queryFn: () => getRetailerCardHeader(),
  });

  useEffect(() => {
    if (isError) {
      sentry.captureMessage('CART_CONSUMER_ERROR', 'error');
    }
  }, [isError]);

  return {
    ...rest,
    isError,
  };
}
