import { cartApi } from '../../_shared/api';

export function removeRetailerCouponCode(cartGuid: string, couponCode: string) {
  return cartApi(`cart/retail/headers/${cartGuid}`, {
    method: 'PATCH',
    body: {
      removeCouponCode: couponCode,
    },
  });
}
