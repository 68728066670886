import { getCookieUserToken, setCookieUserToken } from '../../_shared/utils';

export function getTokenFromParamsOrCookie(): string | undefined {
  const queryParams = new URLSearchParams(window.location.search);
  let token: string = queryParams.get('token') || '';

  if (token) {
    setCookieUserToken(token);
  } else {
    token = getCookieUserToken();
    if (!token) {
      return undefined;
    }
  }

  return token;
}
