import { PostProcessorModule } from 'i18next';
import { stringFormatter, StringFormat } from './formatter';

type CaseType = StringFormat;

interface CaseProcessorOptions {
  case: CaseType;
}

export const caseProcessor: PostProcessorModule = {
  name: 'case',
  type: 'postProcessor',

  process(value, _key, options: Partial<CaseProcessorOptions>) {
    if (!options.case) return value;

    if (stringFormatter[options.case]) return stringFormatter[options.case](value);

    return value;
  },
};
