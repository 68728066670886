import { PHOTOSI_LOGIN_URL, PHOTOSI_URL } from '../../settings';
import { getLanguage } from './cookieUtils';

const SUPPORTED_LANGUAGES = ['it', 'es'];

/**
 *
 * @param next? next url to redirect after login
 */
export function goToLoginPage(next: string = '') {
  const lang = getLanguage();
  const redirectLang = SUPPORTED_LANGUAGES.includes(lang) ? lang : 'it';
  window.location.assign(`${PHOTOSI_LOGIN_URL?.replace('LANG', redirectLang)}?next=${window.location.origin}${next}`);
}

/**
 * Navigates to the update info page with the specified profile page translations and optional next URL.
 * @param  options.profilePageTranslations - The profile page translations.
 * @param options.next - The optional next URL.
 */

export function goToUpdateInfoPage({
  profilePageTranslations,
  next,
}: {
  profilePageTranslations: string;
  next?: string;
}) {
  const lang = getLanguage();
  const redirectLang = SUPPORTED_LANGUAGES.includes(lang) ? lang : 'it';
  window.location.assign(
    `${PHOTOSI_URL}${redirectLang}/${profilePageTranslations}?next=${window.location.href}${next}`,
  );
}
