import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Icon, Input } from '@photosi/web-design-system';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useAddRetailerCouponCode, useRemoveRetailerCouponCode } from '../../hooks';
import { useState } from 'react';
import { RefusedCouponModal } from './RefusedCouponModal';
import { CouponErrorCode } from '../../../_shared/types';
import { AcceptedCouponModal } from './AcceptedCouponModal';

type FormData = z.infer<typeof schema>;

const schema = z.object({
  coupon: z.string(),
});

export const CouponForm: React.FC<{ couponCode: string | null }> = ({ couponCode }) => {
  const { t } = useTranslation();
  const [isAcceptedDialogOpen, setIsAcceptedDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [couponErrorCode, setCouponErrorCode] = useState<CouponErrorCode | null>(null);

  const { mutate: addCouponMutate, isPending: isAddCouponLoading } = useAddRetailerCouponCode();
  const { mutate: removeCouponMutate, isPending: isRemoveCouponPending } = useRemoveRetailerCouponCode();

  const { watch, register, handleSubmit, reset } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      coupon: couponCode || '',
    },
  });

  function resetField() {
    reset({ coupon: '' });
  }

  function onSubmit({ coupon }: FormData) {
    if (couponCode) {
      removeCouponMutate(coupon, { onSuccess: resetField });
      return;
    }
    addCouponMutate(coupon, {
      onSuccess: () => {
        setIsAcceptedDialogOpen(true);
      },
      onError: (error: any) => {
        setCouponErrorCode(error.response._data.errorCode);
        setIsErrorDialogOpen(true);
        resetField();
      },
    });
  }

  return (
    <>
      <form className="pds-flex pds-items-center pds-w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="pds-bg-white pds-w-full pds-rounded-lg pds-flex pds-items-center pds-justify-center has-[:disabled]:pds-ring-0 has-[:focus]:pds-ring-2 has-[:focus]:pds-ring-water-500">
          <Input
            placeholder={t('checkout.coupon.couponPlaceholder')}
            className="pds-w-full pds-bg-transparent focus-visible:pds-ring-0 disabled:pds-text-orange-500 disabled:focus:pds-ring-0"
            variant="filled"
            data-testid="coupon-input"
            disabled={!!couponCode}
            {...register('coupon')}
          />
          {!couponCode && watch('coupon')?.length > 0 && (
            <Button
              size="xs"
              variant="ghost"
              className="pds-px-0 pds-pr-2 hover:pds-bg-transparent"
              data-testid="apply-button"
            >
              {isAddCouponLoading ? (
                <Icon name="spinner" className="pds-animate-spin pds-duration-1000" />
              ) : (
                t('checkout.coupon.applyLabel')
              )}
            </Button>
          )}
          {couponCode && (
            <Button
              size="iconSm"
              variant="ghost"
              className="pds-px-0 pds-pr-2 hover:pds-bg-transparent"
              data-testid="remove-coupon-button"
            >
              {isRemoveCouponPending ? (
                <Icon name="spinner" className="pds-animate-spin pds-duration-1000" />
              ) : (
                <Icon name="x" className="pds-size-4 pds-text-gray-500" />
              )}
            </Button>
          )}
        </div>
      </form>
      <RefusedCouponModal
        couponErrorCode={couponErrorCode as CouponErrorCode}
        isDialogOpen={isErrorDialogOpen}
        setIsDialogOpen={setIsErrorDialogOpen}
      />
      <AcceptedCouponModal isDialogOpen={isAcceptedDialogOpen} setIsDialogOpen={setIsAcceptedDialogOpen} />
    </>
  );
};
