import { Container, FooterPayments, Header } from '@photosi/web-design-system';
import { Navigate } from '../../../_shared/components';
import { useGetUserType } from '../../../_shared/hooks';
import { PATHS } from '../../../paths';
import { Outlet } from 'react-router-dom';

export const CartConsumerLayout: React.FC = () => {
  const { isConsumerUser } = useGetUserType();

  if (!isConsumerUser) {
    return <Navigate to={{ pathname: `/${PATHS.SHOP}` }} keepSearchParams />;
  }

  return (
    <>
      <Header data-testid="header" />
      <Container mxAuto>
        <Outlet />
      </Container>
      <FooterPayments data-testid="footer" />
    </>
  );
};
