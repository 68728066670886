import { InitOptions } from 'i18next';
import { it } from './it';
import { en } from './en';
import { nl } from './nl';
import { de } from './de';
import { fr } from './fr';
import { es } from './es';
import { caseProcessor } from './formatter/case-processor';
import { getLanguage } from '../utils';

export const initI18n: InitOptions = {
  resources: {
    it,
    en,
    de,
    es,
    fr,
    nl,
  },
  postProcess: [caseProcessor.name],
  lng: getLanguage(),
  fallbackLng: 'it',
};
