import { Header } from '@photosi/web-design-system';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

export const Error404: React.FC = () => {
  useEffect(() => {
    Sentry.captureMessage('ErrorBoundary', 'error');
  }, []);

  return (
    <>
      <Header />
      <div className="pds-flex pds-items-center pds-justify-center pds-h-screen">
        <img
          alt="404 Image"
          className="pds-w-half"
          src="https://404.photosi.com/hubfs/PC_SITE_2019/images/404_pc_photo_lg.jpg"
        />
        <div className="pds-flex pds-center pds-column pds-w-half">
          <img alt="404 Text" src="https://404.photosi.com/hubfs/PC_SITE_2019/images/404_text.svg" />
        </div>
      </div>
    </>
  );
};
