import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  Typography,
} from '@photosi/web-design-system';
import { Trans, useTranslation } from 'react-i18next';
import promoSuccess from '../../../_shared/assets/images/promo-success.svg';
import { useGetRetailerCartHeader } from '../../hooks';

type Props = {
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
};

export const AcceptedCouponModal: React.FC<Props> = ({ isDialogOpen, setIsDialogOpen }) => {
  const { t } = useTranslation();
  const { data, isRefetching } = useGetRetailerCartHeader();

  return (
    <>
      {!isRefetching && data && (
        <Dialog onOpenChange={setIsDialogOpen} open={isDialogOpen}>
          <DialogContent
            className="pds-flex pds-flex-col pds-justify-center pds-items-center"
            data-testid="modal-content"
          >
            <div className="pds-size-36">
              <img src={promoSuccess} className="pds-bg-cover pds-w-full pds-h-full" alt="flamingo dancing" />
            </div>
            <DialogHeader className="pds-mt-4">
              <DialogTitle>{t('checkout.coupon.acceptedCoupon.title')}</DialogTitle>
            </DialogHeader>
            <DialogDescription className="pds-text-center pds-mt-1">
              <Typography>
                <Trans
                  i18nKey="checkout.coupon.acceptedCoupon.description"
                  values={{
                    code: data?.couponCode,
                    discount: data?.formattedVatIncludedCouponValue,
                    totalPrice: data?.formattedTotalFullConfidentialPrice,
                  }}
                />
              </Typography>
            </DialogDescription>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
