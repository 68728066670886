import * as amplitude from '@amplitude/analytics-browser';
import { track } from '@amplitude/analytics-browser';
import { AMPLITUDE_API_KEY, API_SOFTWARE_VERSION } from '../../settings';
import { isMobile } from '../utils';
import { CartItem, CouponErrorCode } from '../types';
import { FetchError } from 'ofetch';

type AmplitudeInit = {
  userId: string;
  userType: 'retailer' | 'consumer';
};

type CrossSellingProductAdd = {
  category_name: string;
  cross_product_name: string;
  value: string;
};

type CrossSellingAdd = {
  category_name: string;
  value: string;
  accepted: 'True' | 'False';
  source: 'proceed' | 'skip';
};

type CrossSellingView = {
  category_name: string;
};

type AmplitudePaymentsMethodAcceptedTypes =
  | 'paypal'
  | 'credit_card'
  | 'apple_pay'
  | 'google_pay'
  | 'offline'
  | 'in_shop'
  | 'cash_on_delivery'
  | 'scalapay'
  | 'klarna'
  | 'unknown_payment_method';

type UserPurchaseParams = {
  couponCode: string;
  paymentCode: AmplitudePaymentsMethodAcceptedTypes;
  orderId: string;
  vatIncludedFullTotal: number;
  currencyCode: string;
  productQuantity: number;
  mainProductType: string;
  hasConfidentialPrice?: boolean;
};

type CartAddSuccessPayload = {
  userId: number;
  userType: string;
  unirepoLastProductCode: string;
  lastProductCategoryCode: string;
  orderId: string;
  lastProductPrice: number;
  lastProductCurrencyCode: string;
  totalProductsQuantity: number;
};

export class Amplitude {
  private static instance: Amplitude;

  private constructor({ userId, userType }: AmplitudeInit) {
    amplitude.init(AMPLITUDE_API_KEY, userId, {
      defaultTracking: false,
      trackingOptions: { ipAddress: false },
    });
    amplitude.setUserId(userId);
    const platform = isMobile() ? 'Mobile' : 'Desktop';

    const identifyObj = new amplitude.Identify();
    identifyObj.set('platform_type', platform);
    identifyObj.set('user_type', userType);
    amplitude.identify(identifyObj);
  }

  public static init(userInfo: AmplitudeInit): Amplitude {
    if (!Amplitude.instance) {
      Amplitude.instance = new Amplitude(userInfo);
    }
    return Amplitude.instance;
  }

  protected static async logEvent(eventName: string, eventProperties?: any): Promise<void> {
    if (Amplitude.instance) {
      await track(eventName, eventProperties).promise;
    }
  }

  public static crossSellingView = async (data: CrossSellingView) => {
    await Amplitude.logEvent('cross_selling_view', data);
  };

  public static crossSellingProductAdd = async (data: CrossSellingProductAdd) => {
    await Amplitude.logEvent('cross_selling_product_add', data);
  };

  public static crossSellingAdd = async (data: CrossSellingAdd) => {
    await Amplitude.logEvent('cross_selling_add', data);
  };

  public static cartDeleteProducts = async (categoryCode: CartItem['productCategoryCode']) => {
    await Amplitude.logEvent('cart_delete_products', categoryCode);
  };

  public static cartEditProduct = async (categoryCode: CartItem['productCategoryCode']) => {
    await Amplitude.logEvent('cart_edit_products', categoryCode);
  };

  public static cartAddProducts = async (position: 'cart' | 'summary') => {
    await Amplitude.logEvent('cart_add_products', position);
  };

  public static cartQuantityPlus = async (categoryCode: CartItem['productCategoryCode']) => {
    await Amplitude.logEvent('cart_quantity_plus', categoryCode);
  };

  public static cartQuantityMinus = async (categoryCode: CartItem['productCategoryCode']) => {
    await Amplitude.logEvent('cart_quantity_minus', categoryCode);
  };

  public static clientDataView = async () => {
    await Amplitude.logEvent('client_data_view');
  };

  public static clientDataError = async (error: FetchError) => {
    await Amplitude.logEvent('client_data_error', error);
  };

  public static confidentialPriceToggle = async (active: boolean) => {
    await Amplitude.logEvent('confidential_price_toggle', active);
  };

  public static confidentialPriceInfoClick = async () => {
    await Amplitude.logEvent('confidential_price_info_click');
  };

  public static clientDataEdit = async () => {
    await Amplitude.logEvent('client_data_edit', { position: 'summary' });
  };

  public static cartView = async ({ userId }: { userId: number }) => {
    await Amplitude.logEvent('cart_view', {
      userID: userId,
    });
  };
  public static cartAddSuccess = async ({
    lastProductCategoryCode,
    lastProductCurrencyCode,
    lastProductPrice,
    orderId,
    totalProductsQuantity,
    unirepoLastProductCode,
    userId,
    userType,
  }: CartAddSuccessPayload) => {
    await Amplitude.logEvent('cart_add_success', {
      userType,
      orderId,
      userID: userId,
      unirepo_product_code: unirepoLastProductCode,
      productCategoryCode: lastProductCategoryCode,
      version: API_SOFTWARE_VERSION,
      withvalue: lastProductPrice,
      currencyCode: lastProductCurrencyCode,
      quantity: totalProductsQuantity,
    });
  };

  public static orderConfirmationStart = async ({ orderId }: { orderId: string }) => {
    await Amplitude.logEvent('order_confirmation_start', { orderId });
  };

  public static summaryView = async ({ userId }: { userId: number }) => {
    await Amplitude.logEvent('summary_view', {
      userID: userId,
    });
  };

  public static promoCodeSuccess(code: string): void {
    this.logEvent('promo_code_success', {
      promocode_ID: code,
    });
  }
  public static promoCodeRemove(code: string): void {
    this.logEvent('promo_code_remove', {
      promocode_ID: code,
    });
  }

  public static paymentSelected = async (paymentCode: AmplitudePaymentsMethodAcceptedTypes) => {
    await Amplitude.logEvent('payment_select', { paymentype_ID: paymentCode });
  };

  public static paymentSuccess = async (paymentCode: AmplitudePaymentsMethodAcceptedTypes) => {
    await Amplitude.logEvent('payment_success', {
      paymentype_ID: paymentCode,
    });
  };

  public static promoCodeError({
    couponErrorCode,
    couponCode,
  }: {
    couponErrorCode: CouponErrorCode;
    couponCode: string;
  }): void {
    const errorLabelMap: Record<CouponErrorCode, string> = {
      code_not_found: 'coupon code not found',
      consumer_reserved: 'coupon code reserved for consumer',
      invalid_cart_products: 'invalid cart products for coupon',
      min_order_not_reached: 'minimum order not reached for coupon',
      max_usage_reached: 'maximum usage reached for coupon',
      code_expired: 'coupon code expired',
      invalid_discount: 'invalid discount applied',
      software_not_enabled: 'coupon code not enabled on this channel',
      user_max_usage_reached: 'maximum user usage reached for coupon',
      cumulative_error: 'cumulative discount error with coupon',
      retailer_reserved: 'coupon code reserved for retailer',
    };

    this.logEvent('promo_code_error', {
      promoCodeError_ID: errorLabelMap[couponErrorCode] || errorLabelMap.code_not_found,
      promocode_ID: couponCode,
    });
  }

  public static userPurchase = async ({
    couponCode,
    currencyCode,
    mainProductType,
    orderId,
    paymentCode,
    productQuantity,
    vatIncludedFullTotal,
    hasConfidentialPrice,
  }: UserPurchaseParams) => {
    await Amplitude.logEvent('user_purchase', {
      promocode_ID: couponCode,
      paymentype_ID: paymentCode,
      order_id: orderId,
      version: '1.00',
      withvalue: vatIncludedFullTotal,
      currencyCode: currencyCode,
      quantity: productQuantity,
      main_product_type: mainProductType,
      has_confidential_price: hasConfidentialPrice,
    });
  };
  public static setAmplitudeFeatureFlag(featureFlag: string, featureFlagValue: string): void {
    const identify: amplitude.Identify = new amplitude.Identify();
    identify.set(featureFlag, featureFlagValue);
    amplitude.identify(identify);
  }

  public static thankYouPageOrderDeleteClick = async () => {
    await Amplitude.logEvent('thankyoupage_order_delete_click');
  };
}
